<template>
  <div>
    <div class="card-content">
      <div class="content">
        <div class="columns">
          <dl-column
            title="Payment Reference"
            :items="paymentReference"
          ></dl-column>
          <dl-column
            v-if="paymentPlan"
            title="Default Payment Method"
            :items="defaultPaymentMethod"
          ></dl-column>
          <dl-column title="Dates" :items="dateItems"></dl-column>
          <dl-column title="Amounts" :items="amountItems"></dl-column>
        </div>
        <div v-if="paymentPlanErr" class="">
          <b-message
            :title="paymentPlanId"
            type="is-warning"
            :closable="false"
            has-icon
          >
            {{ paymentPlanErr }}
          </b-message>
        </div>
        <h3>Payments</h3>
        <b-table
          :data="cleanPaymentData.payments"
          default-sort-direction="asc"
          default-sort="dateToBeTaken"
        >
          <b-table-column field="name" label="Name" sortable v-slot="props">
            {{ props.row.name }}
          </b-table-column>
          <b-table-column field="type" label="Type" sortable v-slot="props">
            {{ props.row.type }}
          </b-table-column>
          <b-table-column
            field="dateToBeTaken"
            label="Due date"
            sortable
            v-slot="props"
          >
            <span v-if="props.row.dateToBeTaken">{{
              props.row.dateToBeTaken | moment("calendar")
            }}</span>
            <span v-else-if="props.row.type && paymentPlan">{{
              cleanPaymentData.createdDate | moment("calendar")
            }}</span>
            <span v-else>Unknown</span>
          </b-table-column>
          <b-table-column
            field="amount"
            label="Amount"
            sortable
            numeric
            v-slot="props"
          >
            {{ props.row.amount }}
          </b-table-column>
          <b-table-column
            field="paymentMethod"
            label="Payment Method"
            sortable
            v-slot="props"
          >
            {{ findPaymentPlanPayment(props.row) }}
          </b-table-column>
        </b-table>
      </div>
    </div>
  </div>
</template>
<script>
import DlColumn from '@/components/DlColumn.vue';

export default {
  components: { DlColumn },
  props: {
    tenant: String,
    paymentPlan: Object,
    cleanPaymentData: Object,
    paymentPlanErr: String,
  },
  methods: {
    createTimeItem(value) {
      const m = this.$moment;
      return {
        value: m(value).calendar(),
        toolTip: m(value).format('llll'),
      };
    },
    findPaymentPlanPayment(cpPayment) {
      const payment = Object.values(
        this.paymentPlan ? this.paymentPlan.payments : {},
      ).find(
        ppPayment => ppPayment.name === cpPayment.name
          && ppPayment.type === cpPayment.type
          && ppPayment.amount === cpPayment.amount
          && ppPayment.dueDate === cpPayment.dateToBeTaken,
      );

      return payment
        ? `${payment.paymentMethod.paymentProvider}/${payment.paymentMethod.paymentType}`
        : 'Unknown';
    },
  },
  computed: {
    paymentPlanPayments() {
      return Object.keys(this.paymentPlan ? this.paymentPlan.payments : {}).map(
        id => ({
          id,
          ...this.paymentPlan.payments[id],
        }),
      );
    },
    dateItems() {
      return {
        Created: this.createTimeItem(this.cleanPaymentData.createdDate),
      };
    },
    paymentReference() {
      return {
        Merchant: { value: this.cleanPaymentData.merchant.name },
        Customer: { value: this.cleanPaymentData.customer.id || 'Unknown' },
        'Booking Reference': { value: this.cleanPaymentData.bookingReference },
      };
    },
    amountItems() {
      const due = this.paymentPlanPayments
        ? this.paymentPlanPayments.reduce((prev, curr) => prev + curr.amount, 0)
        : 0;
      const taken = this.paymentPlanPayments
        ? this.paymentPlanPayments.reduce(
          (prev, curr) => prev
              + (curr.ledger.some(e => e.code === 2001) ? curr.amount : 0),
          0,
        )
        : 0;

      return {
        Due: { value: due },
        Taken: { value: taken },
        Outstanding: { value: due - taken },
      };
    },
    defaultPaymentMethod() {
      const paymentMethod = this.paymentPlan.defaultPaymentMethod;

      const fields = {};
      Object.entries(paymentMethod).forEach(([key, val]) => {
        const label = key.split(/(?=[A-Z])/);
        label[0] = label[0].charAt(0).toUpperCase() + label[0].slice(1);

        fields[label.join(' ')] = { value: val };
      });

      return fields;
    },
  },
};
</script>
