<template>
  <div class="column">
    <h3>{{title}}</h3>
    <dl>
      <template v-for="item in itemList">
        <dt :key="item.dtId" class="metaList__label has-text-weight-semibold">
          {{item.id}}
        </dt>
        <dd :key="item.ddId">
          <b-tooltip v-if="item.toolTip" :label="item.toolTip" dashed>
            {{item.value}}
          </b-tooltip>
          <span v-else>
            {{item.value}}
          </span>
        </dd>
      </template>
    </dl>
  </div>
</template>
<script>
/* istanbul ignore file */
export default {
  name: 'DlList',
  props: {
    title: String,
    items: Object,
  },
  computed: {
    itemList() {
      return Object
        .keys(this.items)
        .map((key, i) => ({
          dtId: i,
          ddId: `dd_${i}`,
          id: key,
          toolTip: this.items[key].toolTip,
          value: this.items[key].value,
        }));
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~bulma/sass/utilities/mixins";

.metaList__label {
  @include desktop {
    display: block;
    float: left;
  }
  min-width: 120px;
  margin-right: 10px;
}

.metaList__label::after {
  content: ":";
}
</style>
