<template>
  <div class="main-view">
    <h2 v-if="cleanPaymentData" class="title is-4">
      Payment Plan: {{ paymentPlanId }} -
      {{ cleanPaymentData.customer.names.join(" ") }}
    </h2>
    <div v-if="cleanPaymentDataErr" class="">
      <b-message
        :title="paymentPlanId"
        type="is-warning"
        :closable="false"
        has-icon
      >
        {{ cleanPaymentDataErr }}
      </b-message>
    </div>
    <template v-if="cleanPaymentData">
      <detail-links
        :paymentProvider="cleanPaymentData.merchant.paymentProvider"
      ></detail-links>
      <router-view
        :tenant="tenant"
        :payment-plan="paymentPlan"
        :clean-payment-data="cleanPaymentData"
        :payment-plan-err="paymentPlanErr"
      ></router-view>
    </template>
  </div>
</template>
<script>
import DetailLinks from './DetailLinks.vue';

export default {
  name: 'PaymentPlanIndex',
  components: { DetailLinks },
  mounted() {
    const dataService = this.$dataService(this.tenant);

    dataService.paymentPlans
      .get(this.paymentPlanId)
      .then((result) => {
        this.paymentPlan = result.data;
      })
      .catch((err) => {
        if (err.response.status === 404) {
          this.paymentPlanErr = `The payment response for ID ${this.paymentPlanId} does not exist. That means there was a problem processing the payment or the user might not have completed the payment`;
        } else {
          this.paymentPlanErr = 'Failed to load payment plan. Try again later';
        }
      })
      .finally(() => {
        this.isLoading = false;
      });

    dataService.cleanPaymentData
      .get(this.paymentPlanId)
      .then((result) => {
        this.cleanPaymentData = result.data;
      })
      .catch((err) => {
        if (err.response.status === 404) {
          this.cleanPaymentDataErr = `Payment request with ID ${this.paymentPlanId} does not exist.`;
        } else {
          this.cleanPaymentDataErr = 'Failed to load payment request';
        }
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  data() {
    const { paymentPlanId, tenant } = this.$route.params;

    return {
      paymentPlanId,
      tenant,
      paymentPlan: null,
      cleanPaymentData: null,
      isLoading: true,
      cleanPaymentDataErr: null,
      paymentPlanErr: null,
    };
  },
};
</script>
